body {
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
.bg-dark {
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
}

.bg-dark .nav-link {
  display: flex;
  align-items: center;
  font-weight: normal !important;
  padding: 0.8rem;
  border-radius: 0px;
}

.bg-dark .nav-link {
  padding-left: 0.5rem !important;
}

.bg-dark .logo {
  padding: 0.5rem;
}

.bg-dark .nav-link.active {
  background-color: rgb(255, 255, 255, 0.5);
}

.sidebar-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  opacity: 0.9;
}

.sidebar-icon .material-symbols-outlined {
  font-size: 20px;
}

.bg-dark .nav-link[data-toggle="dropdown"]::after {
  opacity: 0.5;
  transform: rotate(-90deg);
}

.layout-nav-side > .navbar .navbar-brand {
  margin-bottom: 1.5rem;
}

.rdt_Table input[type="checkbox"] {
  min-height: auto;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgb(255, 255, 255, 0.5);
}

.card-list .btn-light {
  background-color: #d4d8dc;
}

.content-wrapper {
  display: flex;
  align-items: stretch;
}

.side-panel {
  border-right: 1px solid #dee2e6;
  background-color: white;
  width: 25%;
  height: 100vh;
}

.side-panel .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: #e9ecef;
}

.side-panel .nav-tabs .nav-link {
  overflow-wrap: break-word;
}

.btn svg {
  margin-right: 5px;
}
.l0 {
  line-height: 0px !important;
}

.icon-light {
  color: #ededed;
}

.icon-lg {
  font-size: 24px;
}

.icon-md {
  font-size: 14px;
}

.icon-sm {
  font-size: 10px;
}

.bg-op-5 {
  background-color: rgb(255, 255, 255, 0.5);
}

.btn-icon svg {
  font-size: 13px;
}

.sidebar-right {
  width: 0px;
  position: fixed;
  height: 100vh;
  transition: 0.3s all ease;
  right: 0;
  top: 0;
  background-color: white;
}

.sidebar-right .content {
  position: relative;
  width: 100%;
  height: 100%;
}

.sidebar-right .content .footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
}

.sidebar-right.show {
  width: 25%;
  z-index: 9999;
}

/*
.sidebar-right .form-control,
.input-group-text {
  border: 0;
  background-color: #f8f9fa;
}
*/

.sidebar-right .input-group-text svg {
  color: #ededed;
}

@media screen and (max-width: 768px) {
  .sidebar-right.show {
    width: 100% !important;
  }
}

.table-search {
  display: flex;
  align-items: center;
  width: 24px;
  transition: 0.3s all ease;
}

.table-search:hover {
  width: 100px;
  transition-delay: 0.1s;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.react-datepicker {
  font-family: inherit !important;
  font-size: 0.8rem;
  background-color: #fff;
  border-radius: 5px;
  color: #6c757d;
  border: 0 !important;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
    0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08) !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--primary) !important;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--light) !important;
  border-bottom: 1px solid var(--light) !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}

.row-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

icon-sm {
  font-size: 10px !important;
}

.input-with-icon {
  position: relative;
  display: inline-block;
}

.input-with-icon input {
  padding-left: 30px; /* Adjust the padding to accommodate the icon */
}

/* Styles for the icon */
.input-with-icon .icon {
  position: absolute;
  left: 8px; /* Adjust the icon position as needed */
  top: 50%;
  transform: translateY(-50%);
}

/* Additional styles for visual enhancements (optional) */
.input-with-icon input {
  border: 4px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  padding: 4px 32px;
  width: 10px;
  background-color: transparent;
}

.input-with-icon input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 2.43;
  opacity: 0.2;
}

.input-with-icon input:focus {
  outline: 0;
  border: 2px solid var(--primary) !important;
  width: 200px;
}

.input-with-icon input:hover {
  width: 200px;
  border: 2px solid var(--primary) !important;
}

.icon-xl {
  font-size: 32px;
}

.login-bg-dark {
  background-color: #152a49 !important;
}

.notif {
  position: fixed;
  bottom: 0;
  max-width: 300px;
  margin-bottom: 64px;
  z-index: 99999;
  right: 0;
}

.small-box {
  position: relative;
  display: block;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 15px;
}
.bg-yellow {
  background-color: #f39c12 !important;
}

.small-box > .inner {
  padding: 10px;
}

.small-box .icon {
  position: absolute;
  top: auto;
  bottom: 5px;
  right: 5px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}

.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.small-box p {
  font-size: 15px;
  color: rgb(255, 255, 255, 0.4);
}

.small-box h3 {
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}

.bg-aqua {
  background-color: #00c0ef !important;
}
.bg-red {
  background-color: #f56954 !important;
}

.navbar-dark .nav-small .nav-link {
  margin-left: 28px;
}

.child-icon {
  display: none;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.navbar-dark .nav-small .nav-item:hover .child-icon {
  display: inline-block !important;
  opacity: 1;
}

.navbar-dark .nav-small .nav-item:active .child-icon {
  display: inline-block !important;
  opacity: 1;
}

.slide-down {
  animation: slide-down 0.5s ease;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-header {
  white-space: nowrap;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.65rem;
  color: #b7b9cc;
}

.bg-dark .dropright:hover .dropdown-menu {
}

.bg-dark .dropdown-menu a {
}

.btn-primary {
}

.nav-collapse {
  margin-left: 18px;
}

.nav-collapse .nav-link.active {
  margin-right: 18px !important;
}

/* .breadcrumb-bar .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  color: #6c757d;
  font-weight: 400;
}
*/

.tableWrapper {
  border-radius: 0.5rem;
  background: #e9ecef;
  padding: 4px;
}

.rdt_TableBody {
  border: 2px solid #eeeef0;
  background: transparent;
}

/* custom.css */
.modal-blur .modal-content {
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Make modal background slightly transparent */
  backdrop-filter: blur(5px); /* Apply blur effect */
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5); /* Adjust the background color */
}

.modal-backdrop.show {
  opacity: 1;
  backdrop-filter: blur(5px); /* Apply blur effect to the backdrop */
}

@keyframes growIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-grow .modal-content {
  animation: growIn 0.3s ease-out;
}

.bg-alt {
  background: #f7f7f8;
}

.material-symbols-sharp {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  vertical-align: middle;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 100, "GRAD" 0, "opsz" 24;
}

.canvas {
  background-color: white;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  z-index: 9999;
  transition: 0.3s ease all;
}

.canvas.show {
  width: 35%;
}

.box-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.bg-dark .logo {
  padding: 0;
}
